import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

const API_BASE_URL = process.env.REACT_APP_FINDER_API_BASE_URL;

const isBasicAuth = process.env.REACT_APP_FINDER_API_USE_BASIC_AUTH === 'true' || false;
const encodedCredentials = (isBasicAuth) ? btoa(
    `${process.env.REACT_APP_FINDER_API_BASIC_AUTH_USER}:${process.env.REACT_APP_FINDER_API_BASIC_AUTH_PASS}`
  ) : null;

export const useFinderApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const handleResponse = async (response) => {
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Something went wrong');
    }
    return response.json();
  };
  
  const getOpenNeeds = useCallback(async () => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs?status=open`, {
      headers: headers,
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const getReservedNeeds = useCallback(async () => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs?status=reserved`, {
      headers: headers,
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const getNeedById = useCallback(async (id) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };
    
    const response = await fetch(`${API_BASE_URL}/needs/${id}`, {
      headers: headers,
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const createNeed = useCallback(async (needData) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(needData),
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const updateNeedForId = useCallback(async (id, needData) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs/${id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(needData),
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const searchSpaces = useCallback(async (id) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs/${id}/search`, {
      method: 'POST',
      headers: headers,
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const makeReservation = useCallback(async (id, reservationData) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs/${id}/reservation`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(reservationData)
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);
  
  const cancelReservation = useCallback(async (id, cancellationReason) => {
    let authString;
    if (isBasicAuth) {
      authString = `Basic ${encodedCredentials}`;
    }
    else {
      const token = await getAccessTokenSilently();
      authString = `Bearer ${token}`;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${authString}`,
    };

    const response = await fetch(`${API_BASE_URL}/needs/${id}/reservation/cancellation`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({cancellation_reason: cancellationReason})
    });
    return handleResponse(response);
  }, [getAccessTokenSilently]);

  return { 
    getOpenNeeds,
    getReservedNeeds,
    getNeedById,
    createNeed,
    updateNeedForId,
    searchSpaces,
    makeReservation,
    cancelReservation
  };
};
