import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate('/'); // Redirect to dashboard after successful authentication
      } else {
        navigate('/login'); // Redirect to login if something went wrong
      }
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <div>Loading...</div>;
};

export default AuthCallback;
