import React, { useEffect, useState } from 'react';
import { Card } from './ui/Card';
import { Button } from './ui/Button';
import { useParams, useNavigate } from 'react-router-dom';
//import { getNeedById } from './api';
import { useFinderApi } from './useFinderApi';


const NeedDetail = () => {
  const {getNeedById} = useFinderApi()

  const { id } = useParams();
  const [need, setNeed] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getNeedById(id)
      .then((data) => setNeed(data))
      .catch((error) => console.error('Error fetching need:', error));
  }, [id, getNeedById]);

  if (!need) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <h2 className="text-xl font-bold mb-4">{need.reference_identifier}</h2>
      <p className="mb-4">Age: {need.age}</p>
      <p className="mb-4">Gender: {need.gender}</p>
      <p className="mb-4">Current Region: {need.current_region}</p>
      <p className="mb-4">Animals: {need.animals}</p>
      <p className="mb-4">Behavioral History: {need.behavioral_history}</p>
      <p className="mb-4">Created At: {need.created_at}</p>
      <p className="mb-4">Updated At: {need.updated_at}</p>
      <Button onClick={() => navigate('/')}>Back to List</Button>
    </Card>
  );
};

export default NeedDetail;