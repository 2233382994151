import React from 'react';
import classNames from 'classnames';

export const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={classNames(
        'px-4 py-2 font-semibold text-white bg-blue-500 rounded-md ',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
