import React from 'react';
import classNames from 'classnames';

export const Card = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames(
        'p-4 bg-white shadow rounded-md',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};