import React, { useEffect, useState } from 'react';
import { Card } from './ui/Card';
import { Button } from './ui/Button';
import { useParams, useNavigate } from 'react-router-dom';
import { useFinderApi } from './useFinderApi';
//import { getNeedById, cancelReservation } from './api';
import { Drawer } from 'vaul';
import { AlertDialog } from './ui/AlertDialog';
import { formatRelative } from 'date-fns';


const ReservationDetail = () => {
  const {getNeedById, cancelReservation} = useFinderApi();
  const { id } = useParams();
  const [need, setNeed] = useState(null);
  const [selectedReason, setSelectedReason] = useState('');
  const [error, setError] = useState(''); 
  const [cancelling, setCancelling] = useState(false); 
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [showDialog, setShowDialog] = useState(false); 
  const navigate = useNavigate();

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleCancelNeed = async () => {
    if (!selectedReason) {
      setError('Please select a reason for cancellation.');
      return;
    }

    setCancelling(true); // Set cancelling state to true

    try {
      await cancelReservation(need.id, selectedReason); // Call the API
      setDrawerOpen(false);
      setShowDialog(true);
    } catch (error) {
      setError(`Failed to cancel reservation: ${error.message}`); // Display error message
    } finally {
      setCancelling(false); // Set cancelling state to false
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate('/'); // Navigate to the main route after closing the dialog
  };

  const handleGoBack = () => {
    navigate('/'); // Navigate back to the main route
  };

  useEffect(() => {
    getNeedById(id)
      .then((data) => setNeed(data))
      .catch((error) => console.error('Error fetching need:', error));
  }, [id, getNeedById]);

  if (!need) {
    return <div>Loading...</div>;
  }

  return (
    <Drawer.Root open={drawerOpen} onOpenChange={setDrawerOpen}>
      <div className='mb-4'>
        <button className="text-blue-600" onClick={() => navigate('/')}>← Back to List</button>
      </div>
      <h2 className="text-xl font-bold p-4 mb-4">Reservation Details</h2>
      <Card className="mb-4 border">
        <h2 className="text-xl font-semibold text-gray-900 mb-2">{need.reference_identifier}</h2>
        <h3 className='text-gray-700 mb-8'>{need?.reservation?.lookup_descriptor}</h3>
        <div className="mb-4">
          <p className="text-gray-600 mb-2">Shelter: {need?.reservation?.shelter?.name}</p>
          <p className="text-gray-600 mb-2">Space: {need?.reservation?.space_type?.name}</p>
          <p className="text-gray-600 mb-2">Reserved: {formatRelative(new Date(need?.reservation?.created_at), new Date())}</p>
        </div>
        


        <Drawer.Trigger asChild>
          <Button>Cancel this reservation</Button>
          </Drawer.Trigger>
      </Card>

      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-white flex flex-col fixed bottom-0 left-0 right-0 max-h-[96%] rounded-t-[10px]">
          
          <div className="max-w-md w-full mx-auto flex flex-col overflow-auto p-4 rounded-t-[10px]">
            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
          
            <Drawer.Title className="text-xl font-bold mb-2">
              Cancel Reservation
            </Drawer.Title>
            
      <p className="text-sm text-gray-700 mb-6">Please select a reason for canceling this reservation:</p>

      {/* Radio Buttons for Cancellation Reasons */}
      <div className="mb-6">
        <div className="mb-4">
          <input
            type="radio"
            id="reason1"
            name="cancelReason"
            value="client_changed_mind"
            onChange={handleReasonChange}
            className="mr-2"
          />
          <label htmlFor="reason1" className="text-sm text-gray-800">
            Client changed their mind
          </label>
        </div>
        <div className="mb-4">
          <input
            type="radio"
            id="reason2"
            name="cancelReason"
            value="transportation_unavailable"
            onChange={handleReasonChange}
            className="mr-2"
          />
          <label htmlFor="reason2" className="text-sm text-gray-800">
            Transportation unavailable
          </label>
        </div>
        <div className="mb-4">
          <input
            type="radio"
            id="reason3"
            name="cancelReason"
            value="other"
            onChange={handleReasonChange}
            className="mr-2"
          />
          <label htmlFor="reason3" className="text-sm text-gray-800">
            Other
          </label>
        </div>
        {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
      </div>

      {/* Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={handleGoBack}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Go Back
        </button>
        <button
          onClick={handleCancelNeed}
          className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          disabled={cancelling} // Disable button while cancelling
        >
          {cancelling ? 'Cancelling...' : 'Cancel Reservation'} 
        </button>
      </div> 

          </div>
        </Drawer.Content>
      </Drawer.Portal>
      <AlertDialog
        isOpen={showDialog}
        title="Cancellation Confirmed"
        message="This reservation has been canceled."
        onClose={handleCloseDialog}
      />
    </Drawer.Root>
  );
};

export default ReservationDetail;