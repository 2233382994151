import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NeedList from './NeedList';
import NeedDetail from './NeedDetail';
import UpdateNeed from './UpdateNeed';
import SearchResults from './SearchResults'; 
import ReservationDetail from './ReservationDetail';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import AuthCallback from './AuthCallback';

const App = () => {
  return (
    <Router>
      <div className="p-4">
        <Routes>
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route path="/" element={<Navigate to="/needs" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/needs"
            element={<ProtectedRoute><NeedList /></ProtectedRoute>}
          />
          <Route
            path="/needs/:id"
            element={<ProtectedRoute><NeedDetail /></ProtectedRoute>}
          />
          <Route
            path="/needs/:id/reservation"
            element={<ProtectedRoute><ReservationDetail /></ProtectedRoute>}
          />
          <Route
            path="/needs/:id/update"
            element={<ProtectedRoute><UpdateNeed /></ProtectedRoute>}
          />
          <Route path="/needs/:id/search-results" element={<SearchResults />} /> 
        </Routes>
      </div>
    </Router>
  );
};

export default App;