import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFinderApi } from './useFinderApi';
import { formatDistanceToNow, formatRelative } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { FaChevronRight, FaSearch  } from 'react-icons/fa';
import { Drawer } from 'vaul';

const NeedList = () => {

  const userName = ""; // TODO: get name from auth 


  const {getOpenNeeds, getReservedNeeds, createNeed} = useFinderApi();
  const navigate = useNavigate();

  const [openNeeds, setOpenNeeds] = useState([]);
  const [reservedNeeds, setReservedNeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [newNeedReferenceIdentifier, setNewNeedReferenceIdentifier] = React.useState("");
  const [isCreatingNeed, setIsCreatingNeed] = useState(false); 

  const { logout } = useAuth0();

  useEffect(() => {
    const getNeeds = async () => {
      try {
        const [openNeedsResponse, reservedNeedsResponse] = await Promise.all([getOpenNeeds(), getReservedNeeds()]);
  
        // TODO: just display top 20 -- there should be an api setting for this
        const sortedOpenNeeds = openNeedsResponse ? openNeedsResponse.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).slice(0, 20) : [];
        const sortedReservedNeeds = reservedNeedsResponse ? reservedNeedsResponse.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) : [];
  
        setOpenNeeds(sortedOpenNeeds);
        setReservedNeeds(sortedReservedNeeds);
      } catch (error) {
        console.error('Error fetching needs:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    getNeeds();

  }, [getOpenNeeds,getReservedNeeds]);

  const handleReservationClick = (needId) => {
    navigate(`/needs/${needId}/reservation`);
  };

  const handleOpenNeedClick = (needId) => {
    navigate(`/needs/${needId}/update`);
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      }
    });
  };

  const handleOpenNewNeedDrawer = () => {
    setNewNeedReferenceIdentifier("");
    setDrawerOpen(true);
  }

  const handleReferenceIdentifierChange = (e) => {
    setNewNeedReferenceIdentifier(e.target.value);
  };

  const handleCreateNewNeed = () => {
    
    setIsCreatingNeed(true); 

    const newNeed = {
      "reference_identifier": newNeedReferenceIdentifier,
      "current_region": null,
      "age": null,
      "gender": null,
      "num_pets": null,
      "behavioral_history": null
    }
    createNeed(newNeed).then((data) => {
      setIsCreatingNeed(false); 
      navigate(`/needs/${data.id}/update`, { state: { isNewNeed: true } });
    }).catch((error) => {
      setIsCreatingNeed(false); 
      console.error('Error creating need:', error);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (

    <Drawer.Root open={drawerOpen} onOpenChange={setDrawerOpen} shouldScaleBackground >
      <div className="flex justify-center min-h-screen">
        <div className="w-full max-w-xl bg-white flex flex-col justify-between">
          <main className="flex-1 overflow-y-auto px-4 py-6 bg-white">
            <header className="mb-8 text-center">
              <div className="flex justify-between items-center text-sm text-gray-600 mb-4">
                <span>{userName}</span>
                <button
                  onClick={handleLogout}
                  className="hover:text-red-500 transition duration-300"
                >
                  Logout
                </button>
              </div>
              <h1 className="text-2xl font-bold text-gray-800">Shelter Ready</h1>
            </header>
            <section className="mb-8">
              <h2 className="text-lg font-semibold text-gray-700 mb-3">Open Reservations</h2>
              {
                (reservedNeeds.length > 0) ? (
                  reservedNeeds.map(need => (
                    <button
                    key={need.id}
                      className="flex justify-between items-center w-full text-left bg-white p-4 rounded-xl shadow-sm border border-gray-200 mb-4 hover:bg-gray-50 focus:outline-none"
                      onClick={() => handleReservationClick(need.id)}
                    >
                      <div>
                        <p className="text-gray-900 mb-2 font-medium">{need.reference_identifier}</p>
                        <p className="text-gray-600">Shelter: {need?.reservation?.shelter?.name}</p>
                        <p className="text-gray-600 mb-2">Space: {need?.reservation?.space_type?.name}</p>
                        <p className="text-sm text-green-900">Reserved {formatRelative(new Date(need?.reservation?.created_at), new Date())}</p>
                      </div>
                      <FaChevronRight className="text-gray-400" /> {/* Icon */}
                    </button>
                  ))
                ) : (
                  <p className="text-gray-400">There are no open reservations</p>
                )
              }             
            </section>
            <section>
              <h2 className="text-lg font-semibold text-gray-700 mb-3">Recent Shelter Searches</h2>
              {
                (openNeeds.length > 0) ? (
                  <ul className="space-y-3">
                    {openNeeds.map(need => (
                      <li key={need.id}>
                        <button
                          className="flex justify-between items-center w-full text-left text-gray-600 border-b border-gray-200 pb-2 hover:bg-gray-50 focus:outline-none"
                          onClick={() => handleOpenNeedClick(need.id)}
                        >
                          <div>
                            <span className="block font-medium text-gray-700">{need.reference_identifier}</span>
                            <div className="text-sm text-blue-900">{formatDistanceToNow(new Date(need.updated_at), { addSuffix: true })}</div>
                          </div>
                          <FaChevronRight className="text-gray-400" /> {/* Icon */}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-400">There are no recent searches</p>
                )
              }
              
            </section>
          </main>
          <footer className="text-center text-sm text-gray-500 py-4">
            <p>&copy; 2024 Caravan Studios, a division of TechSoup</p>
            <p>Version 1.2.0</p>
          </footer>
          <div className="h-24"></div> {/* Spacer div to add extra space below the footer */}
          <button 
            onClick={handleOpenNewNeedDrawer}
            className="fixed bottom-8 right-8 px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 flex items-center justify-center text-lg transition duration-300">
            <FaSearch className="mr-2" />
            Start a new search
          </button>
        </div>
      </div>

      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-white flex flex-col fixed bottom-0 left-0 right-0 max-h-[96%] rounded-t-[10px]">
          
          <div className="max-w-md w-full mx-auto flex flex-col overflow-auto p-4 rounded-t-[10px]">
            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
          
            <Drawer.Title className="text-xl font-bold mb-4">
              Search for shelter space
            </Drawer.Title>
            

            <p className="text-lg text-gray-700 mb-4">Let's label this search so you can find it later:</p>

            {/* Text Input with Label */}
            <div className="mb-6">
        
              
              <input
                type="text"
                id="reference-identifier"
                className="w-full p-2 border border-gray-300 rounded"
                defaultValue={newNeedReferenceIdentifier}
                onChange={handleReferenceIdentifierChange}
              />
              <label className="block text-sm font-medium text-gray-700 mt-2" htmlFor="lookup-descriptor">
                This Reference Identifier is for your reference only
              </label>
            </div>


            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <Drawer.Close>
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
              </Drawer.Close>
              <button
                className={`px-4 py-2 text-white rounded ${newNeedReferenceIdentifier && newNeedReferenceIdentifier.trim().length > 0 ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-300 cursor-not-allowed'} `}
                onClick={handleCreateNewNeed} 
                disabled={isCreatingNeed} 
              >
                {isCreatingNeed ? 'Creating shelter need...' : 'Start searching'}
              </button>
            </div>


          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default NeedList;
